* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: Manrope, sans-serif;
  background-color: #080808;
}
blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}

@media (min-width: 320px) {
  /* ====================>Home section */
  .hero_header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 40;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    background-color: #fff;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }
  .hero_section {
    background-color: #fff;
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .hero_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 7rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }

  .hero_header.show {
    transform: translateX(0);
  }
  .icon {
    font-size: 22px;
  }
  .hero_header img {
    width: 150px;
  }

  .hero_heading {
    display: grid;
  }
  .hero_heading--tag {
    margin-bottom: 1.25rem;
    border-radius: 9999px;
    border: 1px solid #0d0e10;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    max-width: fit-content;
  }
  .hero_heading--text {
    font-size: 4.5rem;
    line-height: 1.1;
  }
  .hero_heading--img {
    display: flex;
    gap: 1rem;
  }
  .hero_heading--img img {
    height: 4rem;
    width: 9rem;
  }
  .hero_description {
    margin-bottom: 3rem;
  }
  .hero_description-text {
    font-size: 1rem;
    line-height: 2;
  }
  .hero_description-bold {
    font-weight: 600;
    color: #0d0e10;
  }
  .navbar {
    display: none;
  }
  /* ==========END=======>Home section */
  .show {
    transform: translateX(450px);
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .logo img {
    height: 1.85rem;
    width: auto;
    cursor: pointer;
  }
  .icon {
    background-color: #fff;
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    background-color: #0d0e10;
    transition-property: all;
    transition-duration: 0.9s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .header_nav {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    margin-left: auto;
    margin-right: auto;
  }

  .header_list ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 23px;
    padding: 0;
    border-bottom-width: 1px;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .header_list ul li {
    padding: 0 1rem;
    list-style: none;
  }
  .header_list ul li a {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    transition-property: all;
    transition-duration: 1s;
  }
  .header_list {
    border-bottom: 1px solid #323232;
  }
  .header_contact {
    padding: 22px 85px;
    border-radius: 50px;
    background-color: #6e43fb;
    margin: 2.5rem 0;
    text-align: center;
  }
  .header_contact a {
    color: #fff;
    text-decoration: none;
    font-size: 19px;
    font-weight: bold;
  }
  .icon_header {
    background-color: black;
    color: #bfbfbf;
    font-size: 30px;
  }
  /* ===>logos */
  .client {
    background-color: #080808;
  }
  .Client_content {
    display: flex;
    align-items: center;
    gap: 40px;
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .Client_content img {
    width: 80%;
  }
  /* =//=>logos */
  /* ==================================>ABOUT */
  .about_container {
    background-color: #0e0e0e;
  }
  .about-title h3 {
    padding-bottom: 1.25rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin: 0;
  }
  .about-content {
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 3rem 1.5rem;
  }
  .about-description p {
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: hsla(0, 0%, 100%, 0.7);
  }
  .About_descripton-brand {
    color: #6e43fb;
  }
  /* ===============END================>ABOUT */

  /* ==================================>OUR SERVICE  */
  .service {
    background-color: #0e0e0e;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .service-content {
    display: flex;
    flex-direction: column;
    color: #fff;
  }
  .service-heading span {
    border: 1px solid #aaa;
    padding: 10px 20px;
    border-radius: 40px;
    font-size: 1.125rem;
    margin-bottom: 3rem;
  }
  .service-heading h3 {
    font-size: 1.875rem;
    line-height: 1.625;
    margin-bottom: 1.25rem;
    margin-top: 3rem;
  }
  .card {
    cursor: pointer;
    border-radius: 0.75rem;
    padding: 1.25rem;
    margin-bottom: 2rem;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke;
  }
  .card:hover {
    background-color: #0d0e10;
  }
  .card img {
    width: 200px;
  }
  .card h5 {
    padding-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0;
  }
  .card p {
    padding-bottom: 1rem;
    font-size: 1.1rem;
    line-height: 1.6rem;
    color: #ffffffb3;
  }
  /* ==================END===============>OUR SERVICE  */

  /* ================================>PORTFOLIO  */
  .portfolio {
    background-color: #efefef;
    margin: 0 20px;
    border-radius: 1rem;
    color: #1e1e1e;
  }
  .containers {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  .porfolio__container {
    padding: 2rem 1.25rem;
    margin-bottom: 2rem;
  }
  .portfolio__tag {
    border: 1px solid #7b7b7b;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    max-width: fit-content;
    margin-bottom: 0.625rem;
  }
  .portfolio__heading {
    margin-bottom: 1.25rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 400;
  }
  .portfolio__card-photo {
    width: 100%;
  }
  .portfolio__card-photo img {
    max-width: 100%;
  }
  .portfolio__card--title {
    margin-bottom: 0.25rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
  }
  .portfolio__card--desc {
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #0c0d0fb3;
  }
  .portfolio__card--tag {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.625rem;
  }
  .portfolio__card--tagline {
    flex-shrink: 0;
    border-radius: 9999px;
    border: 1px solid #0d0e10;
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .portfolio__cta {
    text-align: center;
    width: 100%;
    margin: 3rem 0;
  }
  .portfolio__cta a {
    text-decoration: none;
  }
  .portfolio__cta--button {
    background-color: #0d0e10;
    padding: 0.75rem 2rem;
    border-radius: 9999px;
    color: #fff;
  }
  /* =================END=============>PORTFOLIO   */

  /* ===================================FOOTER */
  .footer__large {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    color: #fff;
  }
  .footer__brand {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 4rem;
  }
  .footer__brand--logo {
    width: 18rem;
  }
  .footer__brand--maps {
    border: 1px solid #ffffff0d;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 1rem;
  }
  .footer__menu--phone {
    margin-top: 1rem;
  }
  .footer__brand--maps--link {
    display: block;
    text-align: center;
    text-decoration: none;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #ffffffb3;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .footer__brand--maps--link:hover {
    color: #6e43fb;
  }
  .footer__navigation {
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
  .footer__menu {
    margin-bottom: 3rem;
  }
  .footer__menu,
  .footer__social {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer__menu--heading,
  .footer__social--heading {
    margin-bottom: 1.25rem;
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #ffffffb3;
  }
  .footer__menu--wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .footer_social_group {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 1.25rem;
    row-gap: 1rem;
  }
  .footer__menu--link,
  .footer__social--link {
    color: #fff;
    font-size: 1.5rem;
    line-height: 2rem;
    text-decoration: none;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  button {
    border: none;
  }
  .footer__cta {
    background-color: #000;
    color: #fff;
    padding: 2rem 0;
    border-top: 1px solid #1e1e1e;
    width: 100%;
  }
  .cta__container {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .footer__cta--heading {
    padding-bottom: 1rem;
    text-align: left;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin: 0 2rem;
  }
  .footer__cta--grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footer__cta--button {
    text-align: left;
    font-size: 2rem;
    line-height: 2.5rem;
    line-height: 1.375;
    color: #ffffffb3;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .footer__cta--arrow {
    font-size: xxx-large;
    color: #ffffffb3;
  }
  .footer__copyright {
    color: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-top: 1px solid #1e1e1e;
  }
  /* ===============END FOOTER===========FOOTER */

  /* ===============CONTACT=========== */
  .contact-content {
    font-weight: 100;
    margin-top: 4rem;
    padding-top: 6rem;
    min-height: 100vh;
    background-color: rgb(255 255 255);
  }
  .contact-heading-tag {
    font-weight: 400;
    border: 1px solid #0c0d0f;
    color: #0c0d0f;
    width: max-content;
    border-radius: 9999px;
    padding: 0.725rem 1.3rem;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
  .contact-heading {
    padding: 0 1rem;
    max-width: 1280px;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-bottom: 10px;
  }
  .contant-heading-main {
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 1.375;
    max-width: 190%;
    text-transform: capitalize;
  }
  .form-contact-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .checkbox {
    width: 100%;
  }
  .contact-checkbox {
    display: flex;
    flex-direction: column;
  }
  .contact-chekbox-heading {
    font-weight: 300;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-top: 2rem;
    margin-bottom: 1.25rem;
  }
  .contact-checkbox-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
  }
  .contact-radio,
  .contat-upload {
    margin-top: 4rem;
  }
  .contact-radio-heading {
    font-weight: 300;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 1.25rem;
    color: #111827;
  }
  .contact-radio-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
  }
  .contact-upload-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .contact-upload-desc {
    font-size: 2rem;
    line-height: 1.75rem;
    color: #0c0d0f;
    text-decoration-line: underline;
  }
  .contact-form-input {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }
  .contact-form-input label {
    display: flex;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #6b7280;
    margin-top: 1rem;
  }
  .contact-form-input input {
    border: none;
    outline: none;
    border-bottom: 2px solid #cbcbcb;
    background-color: transparent;
    padding: 2rem 0;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #111827;
  }
  .contact-form-input {
    margin-bottom: 10rem;
  }
  .contact-cta {
    background-color: #0c0d0f;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s;
  }
  .contact-cta:hover {
    background-color: #5828f7;
  }
  .contact-cta-submit {
    font-size: 4rem;
    background-color: transparent;
    color: #fff;
  }
  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    background-color: #0c0d0f;
    color: #fff;
    border-top: 1px solid #565656;
  }
  /* ===============END CONTACT=========== */
}

@media (min-width: 500px) {
  .navbar {
    display: none;
  }
  .show {
    transform: translateX(750px);
  }
}
@media (min-width: 640px) {
  .navbar {
    display: none;
  }
  .hero_heading--text {
    font-size: 6rem;
    line-height: 1.2;
  }
  .hero_heading--img img {
    height: 6rem;
    width: 15rem;
  }
  .show {
    transform: translateX(750px);
  }
  .footer__cta--button {
    margin: 0 2rem;
  }
  .footer__cta--arrow {
    font-size: xxx-large;
    color: #ffffffb3;
  }

  /* ===============CONTACT=========== */
  .contact-content {
    font-weight: 100;
    margin-top: 4rem;
    padding-top: 6rem;
    min-height: 100vh;
    background-color: rgb(255 255 255);
  }

  .contact-heading {
    padding: 0 1rem;
    max-width: 1280px;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    margin-bottom: 10px;
  }
  .contant-heading-main {
    font-weight: 400;
    max-width: 90%;
    font-size: 3.75rem;
    line-height: 1.2;
    text-transform: capitalize;
  }
  .contact-heading-tag {
    font-weight: 400;
    border: 1px solid #0c0d0f;
    color: #0c0d0f;
    width: max-content;
    border-radius: 9999px;
    padding: 0.725rem 1.3rem;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
  .form-contact-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .checkbox {
    width: 100%;
  }
  .contact-checkbox {
    display: flex;
    flex-direction: column;
  }
  .contact-chekbox-heading {
    font-weight: 300;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-top: 2rem;
    margin-bottom: 1.25rem;
  }
  .contact-checkbox-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
  }

  .contact-radio,
  .contat-upload {
    margin-top: 4rem;
  }
  .contact-radio-heading {
    font-weight: 300;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 1.25rem;
    color: #111827;
  }
  .contact-radio-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
  }
  .contact-upload-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .contact-upload-desc {
    font-size: 2rem;
    line-height: 1.75rem;
    color: #0c0d0f;
    text-decoration-line: underline;
  }
  .contact-form-input {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }
  .contact-form-input label {
    display: flex;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #6b7280;
    margin-top: 1rem;
  }
  .contact-form-input input {
    border: none;
    outline: none;
    border-bottom: 2px solid #cbcbcb;
    background-color: transparent;
    padding: 2rem 0;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #111827;
  }
  .contact-form-input {
    margin-bottom: 10rem;
  }
  .contact-cta {
    background-color: #0c0d0f;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s;
  }
  .contact-cta:hover {
    background-color: #5828f7;
  }
  .contact-cta-submit {
    font-size: 4rem;
    background-color: transparent;
    color: #fff;
  }
  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    background-color: #0c0d0f;
    color: #fff;
    border-top: 1px solid #565656;
  }
  /* ===============END CONTACT=========== */
}
@media (min-width: 844px) {
  .navbar {
    display: none;
  }
  .show {
    transform: translateX(950px);
  }
  .service-cards {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 1.25rem;
    column-gap: 1.25rem;
  }
  .service,
  .about_container,
  .hero_section {
    padding: 0 4rem;
  }
  .portfolio {
    margin: 0 4rem;
  }
  .hero_header {
    padding: 0 5rem;
  }
  .logo {
    padding: 1.5rem 5rem;
  }
  .hero_heading--text {
    font-size: 8rem;
  }
  .hero_heading--img {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  /* ===============CONTACT=========== */
  .contact-content {
    font-weight: 100;
    margin-top: 4rem;
    padding-top: 6rem;
    min-height: 100vh;
    background-color: rgb(255 255 255);
  }

  .contact-heading {
    padding: 0 1rem;
    max-width: 1280px;
    padding-right: 7rem;
    padding-left: 7rem;
    margin-bottom: 3rem;
  }
  .contact-heading-tag {
    font-weight: 400;
    border: 1px solid #0c0d0f;
    color: #0c0d0f;
    width: max-content;
    border-radius: 9999px;
    padding: 0.725rem 1.3rem;
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
  .contant-heading-main {
    font-weight: 400;
    max-width: 85%;
    font-size: 3.75rem;
    line-height: 1.375;
    text-transform: capitalize;
  }
  .form-contact-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-right: 7rem;
    padding-left: 7rem;
  }
  .checkbox {
    width: 100%;
  }
  .contact-checkbox {
    display: flex;
    flex-direction: column;
  }
  .contact-chekbox-heading {
    font-weight: 300;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-top: 2rem;
    margin-bottom: 1.25rem;
  }
  .contact-checkbox-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
  }

  .contact-radio,
  .contat-upload {
    margin-top: 4rem;
  }
  .contact-radio-heading {
    font-weight: 300;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 1.25rem;
    color: #111827;
  }
  .contact-radio-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
  }
  .contact-upload-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .contact-upload-desc {
    font-size: 2rem;
    line-height: 1.75rem;
    color: #0c0d0f;
    text-decoration-line: underline;
  }
  .contact-form-input {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }
  .contact-form-input label {
    display: flex;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #6b7280;
    margin-top: 1rem;
  }
  .contact-form-input input {
    border: none;
    outline: none;
    border-bottom: 2px solid #cbcbcb;
    background-color: transparent;
    padding: 2rem 0;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #111827;
  }
  .contact-form-input {
    margin-bottom: 10rem;
  }
  .contact-cta {
    background-color: #0c0d0f;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s;
  }
  .contact-cta:hover {
    background-color: #5828f7;
  }
  .contact-cta-submit {
    font-size: 4rem;
    background-color: transparent;
    color: #fff;
  }
  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    background-color: #0c0d0f;
    color: #fff;
    border-top: 1px solid #565656;
  }
  /* ===============END CONTACT=========== */
}

@media (min-width: 896px) {
  .navbar {
    display: none;
  }
  .hero_heading--text {
    font-size: 6rem;
    line-height: 1.175;
    color: #0d0e10;
  }
  .hero_heading--img {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .hero_heading--img img {
    height: 6rem;
    width: 15rem;
  }
  .show {
    transform: translateX(950px);
  }
  .service,
  .about_container,
  .hero_section {
    padding: 0 4rem;
  }
  .portfolio {
    margin: 0 4rem;
  }
  .hero_header {
    padding: 0 5rem;
  }
  .logo {
    padding: 1.5rem 5rem;
  }
  .service-cards {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  /* ===============CONTACT=========== */
  .contact-content {
    font-weight: 100;
    margin-top: 4rem;
    padding-top: 6rem;
    min-height: 100vh;
    background-color: rgb(255 255 255);
  }

  .contact-heading {
    padding: 0 1rem;
    max-width: 1280px;
    padding-right: 7rem;
    padding-left: 7rem;
    margin-bottom: 3rem;
  }
  .contact-heading-tag {
    font-weight: 400;
    border: 1px solid #0c0d0f;
    color: #0c0d0f;
    width: max-content;
    border-radius: 9999px;
    padding: 0.725rem 1.3rem;
    font-size: 1.3rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
  .contant-heading-main {
    font-weight: 400;
    max-width: 85%;
    font-size: 3.75rem;
    line-height: 1.375;
    text-transform: capitalize;
  }
  .form-contact-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-right: 7rem;
    padding-left: 7rem;
  }
  .checkbox {
    width: 100%;
  }
  .contact-checkbox {
    display: flex;
    flex-direction: column;
  }
  .contact-chekbox-heading {
    font-weight: 300;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-top: 2rem;
    margin-bottom: 1.25rem;
  }
  .contact-checkbox-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
  }

  .contact-radio,
  .contat-upload {
    margin-top: 4rem;
  }
  .contact-radio-heading {
    font-weight: 300;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 1.25rem;
    color: #111827;
  }
  .contact-radio-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
  }
  .contact-upload-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .contact-upload-desc {
    font-size: 2rem;
    line-height: 1.75rem;
    color: #0c0d0f;
    text-decoration-line: underline;
  }
  .contact-form-input {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }
  .contact-form-input label {
    display: flex;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #6b7280;
    margin-top: 1rem;
  }
  .contact-form-input input {
    border: none;
    outline: none;
    border-bottom: 2px solid #cbcbcb;
    background-color: transparent;
    padding: 2rem 0;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #111827;
  }
  .contact-form-input {
    margin-bottom: 10rem;
  }
  .contact-cta {
    background-color: #0c0d0f;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s;
  }
  .contact-cta:hover {
    background-color: #5828f7;
  }
  .contact-cta-submit {
    font-size: 4rem;
    background-color: transparent;
    color: #fff;
  }
  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    background-color: #0c0d0f;
    color: #fff;
    border-top: 1px solid #565656;
  }
  /* ===============END CONTACT=========== */
}
@media (min-width: 1024px) {
  .navbar {
    display: block;
  }
  .show {
    display: none;
  }
  .icon {
    display: none;
  }
  .hero_header img {
    display: none;
  }
  .Navbar_logo img {
    display: block;
  }
  .hero_header {
    display: block;
  }
  .navbar_container {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navbar_list ul {
    display: flex;
    gap: 20px;
  }
  .navbar_list ul li {
    list-style: none;
  }
  .navbar_list ul li a {
    text-decoration: none;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #000000b3;
    cursor: pointer;
  }
  .navbar_list ul li a:hover {
    color: #5828f7;
  }
  .navbar_contact a {
    text-decoration: none;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #6e43fb;
  }
  .hero_content {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 3rem;
    padding-top: 7rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .containers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
  .portfolio__heading {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 1;
  }

  /* ===============CONTACT=========== */
  .contact-content {
    font-weight: 100;
    margin-top: 4rem;
    padding-top: 6rem;
    min-height: 100vh;
    background-color: rgb(255 255 255);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .contact-heading {
    max-width: 1280px;
    padding-right: 4rem;
    padding-left: 4rem;
    margin-bottom: 3rem;
  }
  .contant-heading-main {
    font-weight: 400;
    max-width: 88%;
    font-size: 3.75rem;
    line-height: 1.375;
    text-transform: capitalize;
  }
  .contact-heading-tag {
    font-weight: 400;
    border: 1px solid #0c0d0f;
    color: #0c0d0f;
    width: max-content;
    border-radius: 9999px;
    padding: 0.725rem 1.3rem;
    font-size: 1.4rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
  .form-contact-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .checkbox {
    width: 100%;
  }
  .contact-checkbox {
    display: flex;
    flex-direction: column;
  }
  .contact-chekbox-heading {
    font-weight: 300;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-top: 2rem;
    margin-bottom: 1.25rem;
  }
  .contact-checkbox-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
  }

  .contact-radio,
  .contat-upload {
    margin-top: 4rem;
  }
  .contact-radio-heading {
    font-weight: 300;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 1.25rem;
    color: #111827;
  }
  .contact-radio-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
  }
  .contact-upload-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .contact-upload-desc {
    font-size: 2rem;
    line-height: 1.75rem;
    color: #0c0d0f;
    text-decoration-line: underline;
  }
  .contact-form-input {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }
  .contact-form-input label {
    display: flex;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #6b7280;
    margin-top: 1rem;
  }
  .contact-form-input input {
    border: none;
    outline: none;
    border-bottom: 2px solid #cbcbcb;
    background-color: transparent;
    padding: 2rem 0;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #111827;
  }
  .contact-form-input {
    margin-bottom: 10rem;
  }
  .contact-cta {
    background-color: #0c0d0f;
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s;
  }
  .contact-cta:hover {
    background-color: #5828f7;
  }
  .contact-cta-submit {
    font-size: 4rem;
    background-color: transparent;
    color: #fff;
  }
  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    background-color: #0c0d0f;
    color: #fff;
    border-top: 1px solid #565656;
  }
  /* ===============END CONTACT=========== */
}
@media (min-width: 1280px) {
  .hero_content {
    gap: 8rem;
  }
  .hero_heading--text {
    font-size: 8rem;
    line-height: 1.175;
    color: #0d0e10;
  }
  .hero_content {
    height: 100vh;
    max-width: 1280px;
    padding: 4rem 4rem;
  }

  .about-content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .about-title h3 {
    font-size: 3rem;
    line-height: 1;
  }
  .about-description p {
    font-size: 1.5rem;
    line-height: 2.15;
  }
  .service-cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .containers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
  .portfolio__heading {
    margin-bottom: 2.5rem;
    font-size: 3rem;
    line-height: 1;
  }
  .footer__large {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20rem;
    padding-top: 4rem;
  }
  .footer__navigation {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 10rem;
    padding-top: 0rem;
    padding-bottom: rem;
  }
  .footer__brand {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding-top: 0rem;
  }
  .footer_social_group {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .footer__brand--maps {
    border: none;
    margin: 1rem 3rem;
  }
  .footer__cta--heading {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .footer__cta--button {
    font-size: 4.5rem;
    line-height: 1;
  }
  .footer__cta--arrow {
    max-lines: 20rem;
  }
  .footer__cta--icon {
    margin-left: 3.5rem;
    height: -webkit-fit-content;
    height: fit-content;
    max-width: 148px;
    fill: #ffffffb3;
  }

  /* ===============CONTACT=========== */
  .contact-content {
    font-weight: 100;
    margin-top: 4rem;
    padding-top: 6rem;
    min-height: 100vh;
    background-color: rgb(255 255 255);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .contact-heading {
    max-width: 1280px;
    padding-right: 4rem;
    padding-left: 4rem;
    margin-bottom: 3rem;
  }
  .contant-heading-main {
    font-weight: 400;
    max-width: 53%;
    font-size: 3.75rem;
    line-height: 1.375;
    text-transform: capitalize;
  }
  .contact-heading-tag {
    font-weight: 400;
    border: 1px solid #0c0d0f;
    color: #0c0d0f;
    width: max-content;
    border-radius: 9999px;
    padding: 0.725rem 1.3rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
  .form-contact-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-right: 4rem;
    padding-left: 4rem;
  }
  .checkbox {
    width: 100%;
  }
  .contact-checkbox {
    display: flex;
    flex-direction: column;
  }
  .contact-chekbox-heading {
    font-weight: 300;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-top: 2rem;
    margin-bottom: 1.25rem;
  }
  .contact-checkbox-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
  }
  .contact-radio,
  .contat-upload {
    margin-top: 4rem;
  }
  .contact-radio-heading {
    font-weight: 300;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 1.25rem;
    color: #111827;
  }
  .contact-radio-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
  }
  .contact-upload-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .contact-upload-desc {
    font-size: 2rem;
    line-height: 1.75rem;
    color: #0c0d0f;
    text-decoration-line: underline;
  }
  .contact-form-input {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }
  .contact-form-input label {
    display: flex;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #6b7280;
    margin-top: 1rem;
  }
  .contact-form-input input {
    border: none;
    outline: none;
    border-bottom: 2px solid #cbcbcb;
    background-color: transparent;
    padding: 2rem 0;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #111827;
  }
  .contact-form-input {
    margin-bottom: 10rem;
  }
  .contact-cta {
    background-color: #0c0d0f;
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s;
  }
  .contact-cta:hover {
    background-color: #5828f7;
  }
  .contact-cta-submit {
    font-size: 4rem;
    background-color: transparent;
    color: #fff;
  }
  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    background-color: #0c0d0f;
    color: #fff;
    border-top: 1px solid #565656;
  }
  /* ===============END CONTACT=========== */
}
